import React from 'react';
import WidgetWrapper from './WidgetWrapper'
import Figure from './Figure'

const DashboardROASWidget = (props) => {
  return (
    <WidgetWrapper title="Universal ROAS">
      <Figure {...props} />
    </WidgetWrapper>
  )
}

export default DashboardROASWidget

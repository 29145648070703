import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const DEFAULT_OPTIONS = {
  title: false,
  chart: {
    height: '400px',
  },
  tooltip: {
    shared: true
  }
}

const HighchartsChart = (params) => {
  const { chartOptions } = params
  const combinedOptions = Object.assign({}, DEFAULT_OPTIONS, chartOptions)

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={combinedOptions}
    />
  )
}

export default HighchartsChart

export default {
  position: {
    x: 'right',
    y: 'top'
  },
  types: [
    {
      type: 'primary',
      background: '#5e72e4',
      // className: 'alert-primary',
      icon: {
        className: 'fas fa-check',
        tagName: 'i',
        color: 'white'
      }
    },
    { type: 'secondary',
      background: '#f4f5f7',
      className: '',
    },
    {
      type: 'success',
      background: '#2dce89',
      // className: 'alert-success',
      icon: {
        className: 'fas fa-check',
        tagName: 'i',
        color: 'white'
      }
    },
    {
      type: 'danger',
      background: '#f5365c',
      // className: 'alert-danger',
      icon: { className: 'fas fa-times', tagName: 'i', color: 'white' }
    },
    { type: 'warning',
      background: '#fb6340'
      // className: 'alert-warning',
    },
    {
      type: 'info',
      background: '#11cdef',
      // className: 'alert-info',
      icon: {
        className: 'fas fa-exclamation-triangle',
        tagName: 'i',
        color: 'white'
      }
    },
    { type: 'light',
      background: '#adb5bd'
      // className: 'alert-light',
    },
    { type: 'dark',
      background: '#212529'
      // className: 'alert-dark',
    },
    {
      type: 'alert',
      background: '#f5365c',
      className: '',
      icon: { className: 'fas fa-times', tagName: 'i', color: 'white' }
    },
    {
      type: 'notice',
      background: '#5e72e4',
      className: '',
      icon: {
        className: 'fas fa-check',
        tagName: 'i',
        color: 'white'
      }
    }
  ]
}

import React from 'react';
import WidgetWrapper from './WidgetWrapper'
import Figure from './Figure'

const DashboardRevenueWidget = (props) => {
  return (
    <WidgetWrapper title="Revenue">
      <Figure {...props} />
    </WidgetWrapper>
  )
}

export default DashboardRevenueWidget

import React from 'react';
import WidgetWrapper from './WidgetWrapper'
import Figure from './Figure'

const DashboardOrderCountWidget = (props) => {
  const title = props.title || 'Total Orders'

  return (
    <WidgetWrapper title={title}>
      <Figure {...props} />
    </WidgetWrapper>
  )
}

export default DashboardOrderCountWidget

import React from 'react';
import WidgetWrapper from './WidgetWrapper'
import Figure from './Figure'

const DashboardAdSpendWidget = (props) => {
  return (
    <WidgetWrapper title="Total Spend">
      <Figure {...props} upIsGood={false} />
    </WidgetWrapper>
  )
}

export default DashboardAdSpendWidget

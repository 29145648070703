import Rails from '@rails/ujs'
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Form, FormGroup, Label, Input } from 'reactstrap';

const ConnectShopifyButton = (props) => {
  const { label, authPath } = props

  const [isShowingModal, showModal] = useState(false)
  const toggle = () => showModal(!isShowingModal);

  const handleSubmit = (event) => {
    const form = event.target
    const shopName = form.querySelector('#shopName').value
    const action = form.action
    const appendOperator = action.includes('?') ? '&' : '?'

    form.action = `${action}${appendOperator}shop=${shopName}`
  }

  return (
    <div>
      <Button color="outline-info" onClick={toggle}>{label}</Button>
      <Modal isOpen={isShowingModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Connect Shopify</ModalHeader>
        <ModalBody>
          <Form action={authPath} method="POST" onSubmit={handleSubmit} data-turbo="false">
            <Input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
            <FormGroup>
              <Label for="shopName">Shopify Store Name</Label>
              <Input name="shop" id="shopName" placeholder="eg: my-store.myshopify.com" required/>
            </FormGroup>

            <div className="text-right">
              <Button color="outline-secondary" onClick={toggle}>Cancel</Button>
              <Button type="submit" color="primary">{label}</Button>
            </div>
          </Form>

        </ModalBody>
      </Modal>
    </div>
  );
}

ConnectShopifyButton.defaultProps = {
  label: 'Connect Shopify'
}

export default ConnectShopifyButton

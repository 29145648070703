import React from 'react'
import WidgetWrapper from './WidgetWrapper'

const DashboardRevenueWidget = (props) => {
  return (
    <WidgetWrapper title="Profit Breakdown">
      <div className="table-responsive">
        <table className="table table-sm">
          <tbody>
            <tr>
              <th scope="row">Revenue</th>
              <td className="text-right">{props.data.orderStats.totals.revenue.format}</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row" className="font-weight-normal">— Cost of Goods</th>
              <td className="text-right">{props.data.orderStats.totals.cost.format}</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">Gross Profit</th>
              <td className="text-right">{props.data.orderStats.totals.grossProfit.format}</td>
              <td className="text-right small align-middle">{props.data.orderStats.totals.grossMargin}</td>
            </tr>
            <tr>
              <th scope="row" className="font-weight-normal">— Marketing Expense</th>
              <td className="text-right">{props.data.multichannelStats.totalSpendMoney.format}</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row" className="font-weight-normal">— Custom Costs</th>
              <td className="text-right">{props.data.profitBreakdown.customCosts.format}</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">Net Profit</th>
              <td className="text-right">{props.data.profitBreakdown.netProfit.format}</td>
              <td className="text-right small align-middle">{props.data.profitBreakdown.contributionRate}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </WidgetWrapper>
  )
}

export default DashboardRevenueWidget

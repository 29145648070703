import consumer from './consumer'
import CableReady from 'cable_ready'
import { Notyf } from 'notyf'
import notyfConfig from '../shared/notyf_config'

let channel
//
// document.addEventListener('turbo:before-cache', () => {
//   const notyf = new Notyf()
//   notyf.dismissAll()
// })

document.addEventListener('turbo:load', () => {
  if (channel) return

  channel = consumer.subscriptions.create('UsersChannel', {
    received (data) {
      if (!data) channel = undefined
      if (data.notification) notify(data.notification)
      if (data.cableReady) CableReady.perform(data.operations)
    }
  })
})

function notify (notification) {
  const [type, message, duration] = notification
  notyfConfig.duration = duration
  new Notyf(notyfConfig).open({ type, message })
}

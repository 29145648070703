import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const RevenueAndRoasOverTimeChart = (params) => {
  const { roasData, revenueData, xAxis } = params

  const chartOptions = {
    chart: {
      height: "400px",
      inverted: false
    },

    title: false,
    series: [
      {
        type: 'column',
        name: 'Revenue',
        data: revenueData,
        yAxis: 1
      },
      {
        type: 'spline',
        name: 'ROAS',
        data: roasData,
      }
    ],
    xAxis: {
      categories: xAxis,
    },
    yAxis: [
      {
        title: {
          text: "ROAS"
        }
      },
      {
        title: {
          text: "Revenue"
        },
        opposite: true,
        labels: {
          format: '${value}'
        }
      }
    ],
    tooltip: {
      shared: true
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}

export default RevenueAndRoasOverTimeChart

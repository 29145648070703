// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import 'chartkick/highcharts'
import * as ActiveStorage from '@rails/activestorage'
import 'notyf'
import debounced from 'debounced'

import 'channels'
import 'controllers'

require('bootstrap')
import './application.scss';

const setupHovers = () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
}

const teardownHovers = () => {
  $('[data-toggle="tooltip"]').tooltip('dispose');
}

document.addEventListener('turbo:load', setupHovers)
document.addEventListener('cable-ready:after-morph', setupHovers);
document.addEventListener('cable-ready:before-morph', teardownHovers);


Rails.start()
ActiveStorage.start()

const ReactRailsUJS = require('react_ujs');

// Patch for Turbo (not Turbolinks)
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('cable-ready:after-morph', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('cable-ready:before-morph', ReactRailsUJS.handleUnmount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);

// Import all images for use in views
require.context('../../assets/images', true)

debounced.initialize()

import React from 'react';
import Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';

Heatmap(Highcharts)

function getPointCategoryName(point, dimension) {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
}

const OrdersWeekHeatmap = (props) => {
  const { data } = props

  const chartOptions = {
    chart: {
      type: 'heatmap',
      height: "400px",
      plotBorderWidth: 1
    },

    title: false,

    xAxis: {
      categories: [
        '12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12pm',
        '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'
      ]
    },

    yAxis: {
      categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      title: null,
      reversed: true
    },

    series: [{
      name: 'Sales per Hour of Day',
      borderWidth: 1,
      data: data,
      dataLabels: {
        enabled: true,
        color: '#000000'
      }
    }],

    colorAxis: {
      min: 0,
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[0]
    },

    tooltip: {
      formatter() {
        return this.point.value + '</b> order(s) on ' + getPointCategoryName(this.point, 'y') + ' at <br><b>'
          + getPointCategoryName(this.point, 'x') + '</b>';
      }
    },
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}

export default OrdersWeekHeatmap

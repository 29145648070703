import Rails from '@rails/ujs'
import React, { useState } from 'react'
// import AsyncSelect from 'react-select'
import Select from 'react-select'
import axios from 'axios';
import { Alert, Form, FormGroup, Label, Input, FormText, Button, Row, Col } from 'reactstrap';


const ProjectsInviteParticipant = (props) => {
  // const { projectId } = props
  const { users } = props
  //
  // const fetchUsers = (term, callback) => {
  //   axios.get(`/projects/${projectId}/`)
  // }

  const [selectedUser, setSelectedUser] = useState({})

  const options = users.map(user => {
    return {
      value: user.id,
      label: user.display_name
    }
  })

  const handleChange = (option) => {
    setSelectedUser(option || {}) // Can be null if cleared
  }
  
  const selectedUserPresent = !!selectedUser.value
  const noOptionsMessage = ({inputValue}) => {
    return `${inputValue} is not on your account yet. Invite them below.`
  }

  return (
    <Form action={`/project/invitations`} method="POST">
      <FormGroup>
        <Label>Search for User</Label>
        <Select isSearchable isClearable onChange={handleChange} options={options} noOptionsMessage={noOptionsMessage}/>
      </FormGroup>
      <input type="hidden" name="project_invitation[recipient_id]" value={selectedUser.value || ''} />

      {
        !selectedUserPresent &&
          <div className="mt-5">
            <div className="mb-5 text-center">Or</div>

            <Alert color="light" className="text-dark">
              User not yet on your account? Add them below and invite them to this project at the same time.
            </Alert>

            <Row form>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input name="project_invitation[first_name]" id="firstName" />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input name="project_invitation[last_name]" id="lastName" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="project_invitation[email]" id="email" />
            </FormGroup>
          </div>
      }

      <FormGroup>
        <Label for="role_name">Role</Label>
        <Input type="select" name="project_invitation[role_name]" id="role_name">
          <option value="agency">Agency</option>
          <option value="client">Client</option>
        </Input>
      </FormGroup>

      <div className="text-right">
        <a href="javascript:history.back();" className="btn btn-outline-secondary">Cancel</a>
        <Button color="primary">Send Invitation</Button>
      </div>
    </Form>
  )
}

export default ProjectsInviteParticipant

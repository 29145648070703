import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { ZoomControl, Layer, Feature } from 'react-mapbox-gl';
import React from 'react';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYWRhbS1zaG9wbHl0aWNzIiwiYSI6ImNrcDA4cHp3MDBicXYydnBlOGljNGw0NHUifQ.9uJzu8dsEFWlLlPJbt4WPw'
});

const OrdersMap = (props) => {
  const { orders: data } = props

  const containerStyle = {
    height: 'calc(100vh - 173px)',
    width: 'calc(100vw - 257px)'
  }

  const layerPaint = {
    'heatmap-weight': {
      property: 'current_subtotal_price_in_cents',
      type: 'exponential',
      stops: [[1, 0], [1000, 1]]
    },
    // Increase the heatmap color weight weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': {
      stops: [[0, 0], [5, 1.2]]
    },
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparency color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.25,
      'rgb(103,169,207)',
      0.5,
      'rgb(209,229,240)',
      0.8,
      'rgb(253,219,199)',
      1,
      'rgb(239,138,98)',
      2,
      'rgb(178,24,43)'
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': {
      stops: [[0, 1], [5, 50]]
    }
  }

  const center = [-105.4564845, 39.3920543]

  return (
    <Map
      style="mapbox://styles/mapbox/light-v9"
      containerStyle={containerStyle}
      center={center}
      zoom={[3]}
    >
      <ZoomControl />
      <Layer type="heatmap" paint={layerPaint}>
        {data.map((order, index) => (
          <Feature key={index} coordinates={[order.shipping_lon, order.shipping_lat]} properties={order}/>
        ))}
      </Layer>
    </Map>
  )
}

export default OrdersMap

import React from 'react';
import WidgetWrapper from './WidgetWrapper'
import Figure from './Figure'

const DashboardCPAWidget = (props) => {
  const title = props.title || 'Universal $ / Purchase'

  return (
    <WidgetWrapper title={title}>
      <Figure {...props} upIsGood={false} />
    </WidgetWrapper>
  )
}

export default DashboardCPAWidget

import React from 'react';

const DashboardFigure = (props) => {
  const { value, previousValue, formattedValue, upIsGood = true } = props
  const [icon, className] =
    upIsGood ?
      (value > previousValue ? ['arrow-up', 'text-success'] : ['arrow-down', 'text-danger']) :
      (value < previousValue ? ['arrow-down', 'text-success'] : ['arrow-up', 'text-danger'])

  const changeRate = (value - previousValue) / previousValue
  // const percentageChange = Math.round(Math.abs(changeRate) * 100)
  const percentageChange = (Math.abs(changeRate) * 100).toFixed(2)
  const displayValue = formattedValue || value

  const comparison = <>
    <i className={`fa fa-${icon} ${className} fa-sm mr-2`} />
    {percentageChange}%
  </>

  return (
    <>
      <h3>
        <span className="mr-2">{displayValue}</span>
      </h3>
      <span className="d-block text-muted">
        {previousValue ? comparison : `—`}
      </span>
    </>
  )
}

export default DashboardFigure

import React from 'react';

const DashboardWidgetWrapper = (props) => {
  return (
    <div className="card bg-light shadow-sm border-0">
      <div className="card-body">
        {props.title &&
          <h5 className="card-title font-weight-300">{props.title}</h5>}

        {props.children}
      </div>
    </div>
  )
}

export default DashboardWidgetWrapper

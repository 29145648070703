import ApplicationController from './application_controller'

document.addEventListener('turbo:before-cache', () =>
  document.getElementById('flash').remove()
)

export default class extends ApplicationController {
  duration = 2000
  showClassName = 'animate__fadeInRight'
  hideClassName = 'animate__zoomOut'

  connect () {
    this.show()
  }

  next () {
    if (this.hidden) return this.element.remove()
    this.hideSlow()
  }

  show () {
    this.element.classList.add(
      'animate__animated',
      'animate__faster',
      this.showClassName
    )
  }

  hideSlow () {
    this.element.classList.remove(this.showClassName)
    setTimeout(this.hide.bind(this), this.duration)
  }

  hide () {
    this.element.classList.remove(this.showClassName)
    this.element.classList.add(this.hideClassName)
  }

  get hidden () {
    return this.element.classList.contains(this.hideClassName)
  }
}

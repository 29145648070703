import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, FormGroup, Label, Input, Nav, NavItem, NavLink } from 'reactstrap'
import Calendar from 'react-calendar'
import { parse as parseQueryParams, stringify as stringifyQueryParams} from 'query-string'
import { subDays, subWeeks, format, parseISO, startOfWeek, startOfMonth, endOfWeek, endOfMonth } from 'date-fns'
import { Turbo } from '@hotwired/turbo-rails'

const DEFAULT_PRESET = 'last_7_days'

const DateRangeControl = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [internalDateRange, setInternalDateRange] = useState([new Date(), new Date()])
  const [internalDatePreset, setInternalDatePreset] = useState(DEFAULT_PRESET)

  let parsedQueryParams = parseQueryParams(location.search)
  const presetParam = parsedQueryParams.date_preset
  const dateFromParam = parsedQueryParams.date_from
  const dateToParam = parsedQueryParams.date_to

  const presetUrl = (preset) => {
    parsedQueryParams.date_preset = preset.value
    delete parsedQueryParams.date_from
    delete parsedQueryParams.date_to
    return `?${stringifyQueryParams(parsedQueryParams)}`
  }

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const isActive = (preset) => {
    return preset === (presetParam || DEFAULT_PRESET)
  }

  const findPresetFromParam = (param) => {
    return datePresets.find(preset => preset.value === param)
  }

  const [datePresets, setDatePresets] = useState([
    {
      label: 'Today',
      value: 'today',
      dates: () => [new Date(), new Date()]
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
      dates: () => [subDays(new Date(), 1), subDays(new Date(), 1)]
    },
    {
      label: 'Last 3 Days',
      value: 'last_3_days',
      dates: () => {
        const yesterday = subDays(new Date(), 1)
        const threeDaysAgo = subDays(yesterday, 3)
        return [threeDaysAgo, yesterday]
      }
    },
    {
      label: 'Last 7 Days',
      value: 'last_7_days',
      dates: () => {
        const yesterday = subDays(new Date(), 1)
        const sevenDaysAgo = subDays(yesterday, 7)
        return [sevenDaysAgo, yesterday]
      }
    },
    {
      label: 'Last 14 Days',
      value: 'last_14_days',
      dates: () => {
        const yesterday = subDays(new Date(), 1)
        const fourteenDaysAgo = subDays(yesterday, 14)
        return [fourteenDaysAgo, yesterday]
      }
    },
    {
      label: 'Week to Date',
      value: 'this_week',
      dates: () => [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()]
    },
    {
      label: 'Month to Date',
      value: 'this_month',
      dates: () => [startOfMonth(new Date()), new Date()]
    },
    {
      label: 'Last Week',
      value: 'last_week',
      dates: () => [
        startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
      ]
    },
    {
      label: 'Last Month',
      value: 'last_month',
      dates: () => [
        startOfMonth(subWeeks(new Date(), 1)),
        endOfMonth(subWeeks(new Date(), 1))
      ]
    },
  ])

  const label = (() => {
    return (dateFromParam && dateToParam && `${format(parseISO(dateFromParam), 'yyyy-MM-dd')} - ${format(parseISO(dateToParam), 'yyyy-MM-dd')}`) ||
      (presetParam && findPresetFromParam(presetParam).label) ||
      findPresetFromParam(DEFAULT_PRESET).label
  })()

  const formatShortDate = (date) => {
    return format(date, 'yyyy-MM-dd')
  }

  const onDateChange = (dates) => {
    const [dateFrom, dateTo] = dates

    delete parsedQueryParams.date_preset
    parsedQueryParams.date_from = formatShortDate(dateFrom)
    parsedQueryParams.date_to = formatShortDate(dateTo)

    Turbo.visit(`?${stringifyQueryParams(parsedQueryParams)}`)
  }

  useEffect(() => {
    let parsedQueryParams = parseQueryParams(location.search)
    const presetParam = parsedQueryParams.date_preset
    const dateFromParam = parsedQueryParams.date_from
    const dateToParam = parsedQueryParams.date_to

    if(dateFromParam && dateToParam) {
      setInternalDateRange([parseISO(dateFromParam), parseISO(dateToParam)])
    } else {
      const presetParamOrDefault = presetParam || DEFAULT_PRESET
      setInternalDatePreset(presetParamOrDefault)
      const preset = findPresetFromParam(presetParamOrDefault)
      setInternalDateRange(preset.dates)
    }
  }, [location.search])

  // const onPresetSelect = (event) => {
  //   const preset = findPresetFromParam(event.target.value)
  //   setInternalDatePreset(preset.value)
  //   Turbo.visit(presetUrl(preset))
  // }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="outline-secondary" caret>
        <i className="fa fa-calendar-alt fa-lg mr-3" />
        {label}
      </DropdownToggle>
      <DropdownMenu right className="p-2">

        <div className="mb-3">
          <div className="nav nav-pills flex-wrap nav-justified flex-column flex-md-row">
            {
              datePresets.map(preset => {
                return (
                    <NavLink href={presetUrl(preset)}
                             className="d-flex shadow-none"
                             key={preset.value}
                             active={isActive(preset.value)}
                    >
                      <span className="my-auto mx-auto smallish">{preset.label}</span>
                    </NavLink>
                )
              })
            }
          </div>
        </div>

        {/*<div className="mb-3">*/}
        {/*  <FormGroup>*/}
        {/*    <Label>Date Range</Label>*/}
        {/*    <select onChange={onPresetSelect} className="custom-select">*/}
        {/*      {*/}
        {/*        datePresets.map(preset => {*/}
        {/*          return (*/}
        {/*            <option key={preset.value} value={preset.value} selected={internalDatePreset === preset.value}>*/}
        {/*              {preset.label}*/}
        {/*            </option>*/}
        {/*          )*/}
        {/*        })*/}
        {/*      }*/}
        {/*    </select>*/}
        {/*  </FormGroup>*/}
        {/*</div>*/}

        {/*<Row form className="mb-3">*/}
        {/*  <Col xs={12} md={6}>*/}
        {/*    <FormGroup>*/}
        {/*      <Label>Starting</Label>*/}
        {/*      <Input value={formatShortDate(internalDateRange[0])} />*/}
        {/*    </FormGroup>*/}
        {/*  </Col>*/}

        {/*  <Col xs={12} md={6}>*/}
        {/*    <FormGroup>*/}
        {/*      <Label>Ending</Label>*/}
        {/*      <Input value={formatShortDate(internalDateRange[1])} />*/}
        {/*    </FormGroup>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row>
          <Col xs={12} md={12}>
            <Calendar onChange={onDateChange}
                      value={internalDateRange}
                      selectRange
                      showDoubleView
                      showNeighboringMonth={false}
                      maxDate={new Date()}
                      minDetail="month" />
          </Col>
        </Row>
      </DropdownMenu>
    </Dropdown>
  )
}

export default DateRangeControl
